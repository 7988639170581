import React from "react"
import ParallaxHooklessImage from "../../components/parallax-hookless-image/ParallaxHooklessImage"

import { graphql } from "gatsby"
import TextBlock from "../../components/text-block/TextBlock"
import MarkdownContainer from "../../components/markdown-container/MarkdownContainer"

import Carousel from "react-images";

import style from "./index.module.css";
import { Head } from "../../components/head/Head"
import { ImageCarousel } from "../../components/image-carousel/ImageCarousel"

export default ({ data }) => {
  const photos: {
    sizes: string;
    src: string;
    srcSet: string;
    height: number;
    width: number;
  }[] = data.images.nodes.map((node) => {
    return {
      sizes: node.childImageSharp.sizes.sizes,
      src: node.childImageSharp.sizes.src,
      srcSet: node.childImageSharp.sizes.srcSet,
      height: node.childImageSharp.original.height,
      width: node.childImageSharp.original.width,
    }
  });
  console.log(photos);

  return (
    <React.Fragment>
      <Head title="Alex & Alexis - Our Story" imageSrc={data.portrait.childImageSharp.fluid.src} />
      <ParallaxHooklessImage
        src={data.portrait.childImageSharp.fluid.src}
        height={data.portrait.childImageSharp.fluid.presentationHeight}
        width={data.portrait.childImageSharp.fluid.presentationWidth}
      >
        <h1>Our Story</h1>
      </ParallaxHooklessImage>
      <TextBlock>
        <MarkdownContainer markdownString={data.ourStory.html} />
      </TextBlock>
      <ImageCarousel photos={photos} />
    </React.Fragment>
  )
}

export const query = graphql`
  {
    portrait: file(relativePath: { eq: "zoe-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }

    images: allFile(filter: {relativeDirectory: {eq: "our-story"}}, sort: {fields: name, order: ASC}) {
      nodes {
        childImageSharp {
          sizes(maxWidth: 4096, jpegProgressive: true) {
            src
            sizes
            srcSet
            presentationWidth
            presentationHeight
          }
          original {
            height
            width
          }
        }
      }
    }

    ourStory: markdownRemark(frontmatter: { title: { eq: "our-story" } }) {
      html
    }
  }
`
